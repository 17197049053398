import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Header from '~/components/Header';

import { Container, Layout, LayoutContent, Content } from './styles';

export default function DefaultLayout({ children }) {
  const [collapsed, setCollapsed] = useState(true);

  function onCollapse(collapsed) {
    setCollapsed(collapsed);
  }

  return (
    <Container>
      <Layout>
        <LayoutContent>
          <Content>
            {children}
          </Content>
        </LayoutContent>
      </Layout>
    </Container>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
