import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Row, Col, Spin } from 'antd';

import ModalFinished from './Finished';

import { gameCreateRequest, gameReadRequest, gameUpdateRequest } from '~/store/modules/game/actions';
import { gameTipCheckRequest } from '~/store/modules/gameTip/actions';

import { Container, Content, PlayerContent } from './styles';

export default function Game(props) {
  const dispatch = useDispatch();

  const { id } = props.match.params;
  const black = props?.match?.params.black === '1' ? 1 : 0;

  const registry = useSelector(store => store.game.registry);
  const source = useSelector(store => store.game.game);
  const piecesOut = useSelector(store => store.game.piecesOut);
  const pointBlack = useSelector(store => store.game.pointBlack);
  const pointWhite = useSelector(store => store.game.pointWhite);
  const lastMove = useSelector(store => store.game.lastMove);
  const moves = useSelector(store => store.gameTip.list);

  const [from, setFrom] = useState();
  const [curPlace, setCurPlace] = useState(undefined);
  const [isVisibleFinished, setIsVisibleFinished] = useState(false);

  function setPlaces(piece, place, possible) {
    setCurPlace(place);

    if (piece % 2 !== black) {
      if (piece > 0) {
        setFrom({ piece, place });
        dispatch(gameTipCheckRequest(id, piece, place));
      }
    }
    if (possible && from.piece % 2 !== black) {
      dispatch(gameUpdateRequest(id, from.piece, from.place, place));
    }
  }

  useEffect(() => {
    if (id && id !== '') dispatch(gameReadRequest(id));
    else dispatch(gameCreateRequest());
  }, []);

  return (
    <Container>
      {source.length > 0 && (
        <PlayerContent>
          <Row>
            <Avatar size={40} />
            <div className='player-details'>
              <span>{!black ? registry.userNameWhite : registry.userNameBlack}&nbsp;</span>
              <div className='pieces-out'>
                {piecesOut.filter(p => p.piece % 2 === black ? 0 : 1).map(p => (
                  <img src={p.image} />
                ))}
                <span>{!black ? pointWhite > pointBlack ? `+${pointWhite - pointBlack}` : '' : pointWhite < pointBlack ? `+${pointBlack - pointWhite}` : ''}</span>
              </div>
            </div>
          </Row>
        </PlayerContent>
      )}

      {source && (
        <Content className={source.length <= 0 && 'no-border'}>
          {source.length <= 0 && <Spin />}
          <Row>
            {(!black ? source.slice(0) : source.slice(0).reverse())?.splice(56, 8).map((e, i) => {
              const exists = moves.find(a => a === e.place);
              return (
                <>
                  {i === 0 && <span className='place-info'>{e.place.substring(1, 2)}</span>}
                  <Col key={e.place} data-place={e.place} xs={3} onClick={() => setPlaces(e.piece, e.place, exists)} className={[i % 2 === 0 ? 'bg-white' : 'bg-black', ((e.piece > 0 && e.type === black) || exists) && 'piece-move', (e.place === lastMove?.place || e.place === lastMove?.placePrevious) && 'bg-move', exists && 'place-to-move', (e.place === curPlace && e.piece > 0 && e.type === black) && 'place-selected']}>
                    <img src={e.image} />
                  </Col>
                </>
              )
            })}
          </Row>
          <Row>
            {(!black ? source.slice(0) : source.slice(0).reverse())?.splice(48, 8).map((e, i) => {
              const exists = moves.find(a => a === e.place);
              return (
                <>
                  {i === 0 && <span className='place-info'>{e.place.substring(1, 2)}</span>}
                  <Col key={e.place} data-place={e.place} xs={3} onClick={() => setPlaces(e.piece, e.place, exists)} className={[i % 2 !== 0 ? 'bg-white' : 'bg-black', ((e.piece > 0 && e.type === black) || exists) && 'piece-move', (e.place === lastMove?.place || e.place === lastMove?.placePrevious) && 'bg-move', exists && 'place-to-move', (e.place === curPlace && e.piece > 0 && e.type === black) && 'place-selected']}>
                    <img src={e.image} />
                  </Col>
                </>
              )
            })}
          </Row>
          <Row>
            {(!black ? source.slice(0) : source.slice(0).reverse())?.splice(40, 8).map((e, i) => {
              const exists = moves.find(a => a === e.place);
              return (
                <>
                  {i === 0 && <span className='place-info'>{e.place.substring(1, 2)}</span>}
                  <Col key={e.place} data-place={e.place} xs={3} onClick={() => setPlaces(e.piece, e.place, exists)} className={[i % 2 === 0 ? 'bg-white' : 'bg-black', ((e.piece > 0 && e.type === black) || exists) && 'piece-move', (e.place === lastMove?.place || e.place === lastMove?.placePrevious) && 'bg-move', exists && 'place-to-move', (e.place === curPlace && e.piece > 0 && e.type === black) && 'place-selected']}>
                    <img src={e.image} />
                  </Col>
                </>
              )
            })}
          </Row>
          <Row>
            {(!black ? source.slice(0) : source.slice(0).reverse())?.splice(32, 8).map((e, i) => {
              const exists = moves.find(a => a === e.place);
              return (
                <>
                  {i === 0 && <span className='place-info'>{e.place.substring(1, 2)}</span>}
                  <Col xs={3} onClick={() => setPlaces(e.piece, e.place, exists)} className={[i % 2 !== 0 ? 'bg-white' : 'bg-black', ((e.piece > 0 && e.type === black) || exists) && 'piece-move', (e.place === lastMove?.place || e.place === lastMove?.placePrevious) && 'bg-move', exists && 'place-to-move', (e.place === curPlace && e.piece > 0 && e.type === black) && 'place-selected']}>
                    <img src={e.image} />
                  </Col>
                </>
              )
            })}
          </Row>
          <Row>
            {(!black ? source.slice(0) : source.slice(0).reverse())?.splice(24, 8).map((e, i) => {
              const exists = moves.find(a => a === e.place);
              return (
                <>
                  {i === 0 && <span className='place-info'>{e.place.substring(1, 2)}</span>}
                  <Col key={e.place} data-place={e.place} xs={3} onClick={() => setPlaces(e.piece, e.place, exists)} className={[i % 2 === 0 ? 'bg-white' : 'bg-black', ((e.piece > 0 && e.type === black) || exists) && 'piece-move', (e.place === lastMove?.place || e.place === lastMove?.placePrevious) && 'bg-move', exists && 'place-to-move', (e.place === curPlace && e.piece > 0 && e.type === black) && 'place-selected']}>
                    <img src={e.image} />
                  </Col>
                </>
              )
            })}
          </Row>
          <Row>
            {(!black ? source.slice(0) : source.slice(0).reverse())?.splice(16, 8).map((e, i) => {
              const exists = moves.find(a => a === e.place);
              return (
                <>
                  {i === 0 && <span className='place-info'>{e.place.substring(1, 2)}</span>}
                  <Col key={e.place} data-place={e.place} xs={3} onClick={() => setPlaces(e.piece, e.place, exists)} className={[i % 2 !== 0 ? 'bg-white' : 'bg-black', ((e.piece > 0 && e.type === black) || exists) && 'piece-move', (e.place === lastMove?.place || e.place === lastMove?.placePrevious) && 'bg-move', exists && 'place-to-move', (e.place === curPlace && e.piece > 0 && e.type === black) && 'place-selected']}>
                    <img src={e.image} />
                  </Col>
                </>
              )
            })}
          </Row>
          <Row>
            {(!black ? source.slice(0) : source.slice(0).reverse())?.splice(8, 8).map((e, i) => {
              const exists = moves.find(a => a === e.place);
              return (
                <>
                  {i === 0 && <span className='place-info'>{e.place.substring(1, 2)}</span>}
                  <Col key={e.place} data-place={e.place} xs={3} onClick={() => setPlaces(e.piece, e.place, exists)} className={[i % 2 === 0 ? 'bg-white' : 'bg-black', ((e.piece > 0 && e.type === black) || exists) && 'piece-move', (e.place === lastMove?.place || e.place === lastMove?.placePrevious) && 'bg-move', exists && 'place-to-move', (e.place === curPlace && e.piece > 0 && e.type === black) && 'place-selected']}>
                    <img src={e.image} />
                  </Col>
                </>
              )
            })}
          </Row>
          <Row>
            {(!black ? source.slice(0) : source.slice(0).reverse())?.splice(0, 8).map((e, i) => {
              const exists = moves.find(a => a === e.place);
              return (
                <>
                  {i === 0 && <span className='place-info'>{e.place.substring(1, 2)}</span>}
                  <Col key={e.place} data-place={e.place} xs={3} onClick={() => setPlaces(e.piece, e.place, exists)} className={[i % 2 !== 0 ? 'bg-white' : 'bg-black', ((e.piece > 0 && e.type === black) || exists) && 'piece-move', (e.place === lastMove?.place || e.place === lastMove?.placePrevious) && 'bg-move', exists && 'place-to-move', (e.place === curPlace && e.piece > 0 && e.type === black) && 'place-selected']}>
                    <img src={e.image} />
                    <span className='place-info info-letter'>{e.place.substring(0, 1)}</span>
                  </Col>
                </>
              )
            })}
          </Row>
        </Content>
      )}

      {source.length > 0 && (
        <PlayerContent>
          <Row>
            <Col xs={24} className='player-info'>
              <Avatar size={40} />
              <div className='player-details'>
                <span>{black ? registry.userNameWhite : registry.userNameBlack}</span>
                <div className='pieces-out'>
                  {piecesOut.filter(p => p.piece % 2 === black ? 1 : 0).map(p => (
                    <img src={p.image} />
                  ))}
                  <span>{black ? pointWhite > pointBlack ? `+${pointWhite - pointBlack}` : '' : pointWhite < pointBlack ? `+${pointBlack - pointWhite}` : ''}</span>
                </div>
              </div>
            </Col>
          </Row>
        </PlayerContent>
      )}

      <ModalFinished isVisible={isVisibleFinished} setIsVisible={setIsVisibleFinished} gameId={registry?.id} />
    </Container>
  );
}
