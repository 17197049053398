import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import game from './game/sagas';
import gameTip from './gameTip/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    game,
    gameTip
  ]);
}
