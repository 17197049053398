import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Route from './Route';

import Game from '~/pages/Game';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Game} />
      <Route path="/:id/:black?" exact component={Game} />

      <Redirect to="/" />
    </Switch>
  );
}