export function gameIndexRequest(filters) {
  return {
    type: '@games/INDEX_REQUEST',
    payload: { filters },
  };
}

export function gameIndexSuccess(data) {
  return {
    type: '@games/INDEX_SUCCESS',
    payload: { data },
  };
}

export function gameIndexFailure() {
  return {
    type: '@games/INDEX_FAILURE',
  };
}

export function gameCreateRequest() {
  return {
    type: '@games/CREATE_REQUEST'
  };
}

export function gameCreateSuccess(data) {
  return {
    type: '@games/CREATE_SUCCESS',
    payload: { data },
  };
}

export function gameCreateFailure() {
  return {
    type: '@games/CREATE_FAILURE',
  };
}

export function gameReadRequest(id) {
  return {
    type: '@games/READ_REQUEST',
    payload: { id },
  };
}

export function gameReadSuccess(data) {
  return {
    type: '@games/READ_SUCCESS',
    payload: { data },
  };
}

export function gameReadFailure() {
  return {
    type: '@games/READ_FAILURE',
  };
}

export function gameUpdateRequest(id, piece, placeFrom, placeTo) {
  return {
    type: '@games/UPDATE_REQUEST',
    payload: { id, piece, placeFrom, placeTo },
  };
}

export function gameUpdateSuccess(data) {
  return {
    type: '@games/UPDATE_SUCCESS',
    payload: { data },
  };
}

export function gameUpdateFailure() {
  return {
    type: '@games/UPDATE_FAILURE',
  };
}