export function gameTipCheckRequest(id, piece, place) {
  return {
    type: '@gameTips/CHECK_REQUEST',
    payload: { id, piece, place },
  };
}

export function gameTipCheckSuccess(data) {
  return {
    type: '@gameTips/CHECK_SUCCESS',
    payload: { data },
  };
}

export function gameTipCheckFailure() {
  return {
    type: '@gameTips/CHECK_FAILURE',
  };
}