import { createGlobalStyle } from 'styled-components';

import 'antd/dist/antd.css';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;

    &:focus{
      outline: 0;
    }
  }

  html, body, #root {
  }

  body{
    -webkit-font-smoothing: antialiased !important;
  }

  body, input, button {
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;

    &.type-default {
      background-color: #999a99;
    }
  }

  .clickable {
    cursor: pointer;
  }

  .container-fluid {
    padding: 20px 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .text-danger {
    color: #dc3545 !important;
  }

  .text-success {
    color: #28a745 !important;
  }

  .modal-cat {
    .ant-modal-header, .ant-modal-body, .ant-modal-footer {
      background-color: var(--background);
    }

    .ant-modal-title, .ant-modal-close-x {
      color: var(--primary-text);
    }

    .cat-photo {
      border: 1px solid #fff;
      border-radius: 25px;
    }
  }
  .ant-row {
    width: 100%;
  }
  
  .ant-form-item-explain-error {
    color: #fa8963;
  }
  
  .ant-pagination-item {
    &:focus-visible, &:hover {
      border-color: var(--button-primary-border);
    
      a {
        color: var(--button-primary);     
      }
    }
  
    &-active {
      border-color: var(--button-primary-border);
  
      a {
        color: var(--button-primary);
      }
    }
  }
  
  .ant-pagination {
    &-prev,&-next {
      &:focus-visible, &:hover {
        .ant-pagination-item-link {
          color: var(--button-primary);     
          border-color: var(--button-primary-border);
        }
      }
    }
  }
  
  .ant-form-item-label {
    margin-bottom: -6px;
  }
  
  .ant-picker,.ant-input-number {
    width: 100%;
  }
  
  .ant-table-content {
    overflow-x: auto;
  }
  
  .is-mobile .ant-table {
    font-size: 12px;
  }
  
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: var(--menu-primary) !important;
  }
  
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 2px solid var(--border-primary);
  }
  
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: var(--header-background-secondary);
  }
  
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: var(--button-primary);
  }
  
  .ant-steps-item-process .ant-steps-item-icon {
    background-color: var(--body-background);
    border-color: var(--border-primary);
  }
  
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: var(--border-primary);
  }
  
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: var(--button-primary-border);
  }
  
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: var(--button-primary-border);
  }
  
  .ant-spin-dot-item {
    background-color: var(--button-primary-border);
  }
  
  .ant-modal-content, .ant-modal-header {
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  button, button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
    cursor: pointer;
  }
  
  .btn-fixed {
    position: fixed;
    right: 20px;
    bottom: 20px;
  
    button {
      width: 15vw !important;
      height: 15vw !important;
  
      svg {
        font-size: 5vw;
      }
    }
  
    .btn {
      box-shadow: rgba(0,0,0,.3) 2px 5px 8px;
      user-select: none;
      border: 1px solid transparent;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  
      &.rounded-circle {
        flex-wrap: wrap;
        justify-content: space-around;
        width: 55px;
        height: 55px;
        padding: 0;
        margin: 0;
        padding: 8px 14px;
        border-radius: 50% !important;
        line-height: 1.33;
    
        svg {
          font-size: 18px;
        }
      }
    }
  }
  
  .btn-danger {
    color: var(--button-danger-text);
    border-color: var(--button-danger-border);
    background: var(--button-danger);
  }
  
  .ant-btn:hover, .ant-btn:focus {
    color: var(--button-primary-border);
    border-color: var(--button-primary-border-hover);
  }
  
  .ant-btn-primary {
    color: var(--button-primary-text);
    border-color: var(--button-primary-border);
    background: var(--button-primary);
  }
  
  .ant-btn-primary:hover, .ant-btn-primary:focus {
    color: var(--button-primary-text-hover);
    border-color: var(--button-primary-border-hover);
    background: var(--button-primary-hover);
  }
  @import '_variables.scss';

:root {
  --primary: #ffde00;
  --secondary: #1ebea5;
  --background: #fff;
  --third: #dcdcdc;
  --pink: #ff4081;
  --danger: #f44336;

  --border-primary: #ffde00;
  --border-secondary: #dcdcdc;

  --primary-text: #fff;
  --secondary-text: #e9e3e7;
  --third-text: #736c73;

  --header-background: #fff;
  --header-background-secondary: #4b4350;

  --body-background: #F5F5F5;

  --menu-primary: #332b38;

  --input-text: #fff;
  --input-text-placeholder: #afa39f;
  --input-background: #5d5e5e;
  --input-border: #fff;

  --button-primary: #D8592E;
  --button-primary-hover: #f06b3f;
  --button-primary-text: #fff;
  --button-primary-text-hover: #fff;
  --button-primary-border: #D8592E;
  --button-primary-border-hover: #D8592E;

  --button-danger: #f44336;
  --button-danger-hover: #fd665b;
  --button-danger-text: #F5F5F5;
  --button-danger-text-hover: #F5F5F5;
  --button-danger-border: #dc3545;
  --button-danger-border-hover: #f44336;

  --anchor-primary: #d5ccd1;
  --anchor-primary-hover: rgb(37, 187, 247);
}

.input-text-right {
  input {
    text-align: right;
  }

  &.ant-input-number {
    input {
      padding-right: 8px;
    }
  }
}
`;
