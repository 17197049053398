import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Input } from 'antd';

import Modal from '~/components/Modal';

import { } from '~/store/modules/game/actions';

import pieceWhite from '~/assets/images/13.png';
import pieceBlack from '~/assets/images/14.png';

export default function Finished({ gameId, isVisible, setIsVisible }) {
  const dispatch = useDispatch();

  const loading = useSelector(store => store.game.loading);
  const registry = useSelector(store => store.game.registry);

  return (
    <Modal
      title="Partida concluída"
      visible={isVisible}
      setVisible={setIsVisible}
      onOk={undefined}
      maskClosable={false}
      width={400}
      processing={loading}
    >
      <Row gutter={8}>
        <Col xs={12} style={{ textAlign: 'right' }}>
          <img src={pieceWhite} />
          {registry.winnerWhite ? 1 : 0}
        </Col>

        <Col xs={12} style={{ textAlign: 'left' }}>
          <img src={pieceBlack} />
          {registry.winnerWhite ? 0 : 1}
        </Col>
      </Row>
    </Modal >
  );
}
