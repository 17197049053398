import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { signInSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;

    const formData = new FormData();

    formData.append('email', email);
    formData.append('password', password);

    const response = yield call(api.post, 'login', formData);

    console.log({ response });

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const { token } = result.data;

    console.log({ result });

    const user = {
      id: result.data.id,
      name: result.data.name,
      email: result.data.email,
      active: result.data.active,
      admin: result.data.admin,
      master: result.data.master
    };

    console.log({ user });

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, user));

    history.push('/dashboard');
  }
  catch (err) {
    toast.error(String(err));
    yield put(signFailure());
  }
}

export function* setToken({ payload }) {

}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
]);