import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export default reducers => {
  const persistedReducer = persistReducer({
    key: '@webstation/chess-web',
    storage,
    whitelist: [],
  }, reducers);

  return persistedReducer;
}