import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 77vh;
  border: 1px solid #ccc;
  border-radius: 4px;
  user-select: none;
  cursor: default;

  &.no-border {
    border: 0;
    text-align: center;
  }

  img {
    width: 100%;
    z-index: 7777;
  }

  .piece-move {
    cursor: pointer;
  }

  .bg-white,.bg-black {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .bg-white {
    background-color: #fffde0;
    border: 1px solid #fffde0;
    
    &.bg-move {
      background-color: #f2ff83;
      border: 1px solid #f2ff83;
    }
  }

  .bg-black {
    background-color: #0099aa;
    border: 1px solid #0099aa;

    &.bg-move {
      background-color: #d3d972;
      border: 1px solid #d3d972;
    }
  }

  .place-to-move {
    border: 1px solid #ff0000;
    -moz-box-shadow:    inset 0 0 25px #ff0000;
    -webkit-box-shadow: inset 0 0 25px #ff0000;
    box-shadow:         inset 0 0 25px #ff0000;
  }

  .place-info {
    display: flex;
    position: absolute;
    z-index: 6666;
    width: 100%;
    max-width: 127px;
    height: 100%;
    max-height: 126px;
    justify-content: flex-start;
    padding: 3px;
    font-size: 12px;

    &.info-letter {
      justify-content: flex-end;
      align-items: flex-end;
      text-transform: lowercase;
    }
  }

  .place-selected {
    border: 1px solid #ff0000;
    background-color: #ff655a;
  }
`;

export const PlayerContent = styled.div`
  width: 100%;
  max-width: 77vh;
  user-select: none;
  cursor: default;
  padding: 10px 0;

  .player-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .player-details {
    display: flex;
    flex-direction: column;

    .pieces-out {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      img {
        height: 3vw;
        max-height: 25px;
        margin-right: -5px;
      }

      span {
        margin-left: 7px;
      }
    }
  }

  .ant-avatar {
    margin-right: 8px;
  }
`;
