import styled from 'styled-components';
import { lighten } from 'polished';
import { Layout as _Layout, Form as _Form, Input as _Input, Button as _Button, DatePicker } from 'antd';
import { Link as _Link } from 'react-router-dom';

export const Container = styled(_Layout)`
  min-height: 100vh;

  .logo {
    height: 32px;
    margin: 16px;

    img {
      height: 28px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-items: center;
  justify-content: center;

  &.is-mobile {
    padding: 0;
    color: #000;
  }

  h1 {
    color: #000;
    font-size: 16px;
    font-weight: 700;  }
`;

export const Layout = styled(_Layout)`
  background: var(--background);

  .site-layout-background {
      padding: 0;
  }
`;

export const LayoutContent = styled(_Layout.Content)`
  margin: 0;
`;

export const Form = styled(_Form)`
  width: 100%;
  max-width: 320px;
`;

export const FormItem = styled(_Form.Item)`
  margin-bottom: 10px;

  label {
    color: var(--input-text);
  }

  .input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    input {
      padding-left: 42px;
      z-index: 333;
    }

    svg {
      cursor: text;
      color: var(--input-text);
      position: relative;
      font-size: 16px;
      margin-right: -16px;
      left: 16px;
      z-index: 444;
    }
  }

  input {
    color: var(--input-text);
    background-color: var(--input-background) !important;
    border: 1px solid var(--input-border);
    border-radius: 25px;
    width: 100%;
    font-size: 3.7vw;
    font-weight: 300;
    height: 12vw;
    letter-spacing: 0.2vw;
    padding: 10px 20px;
    margin: 0;
    margin-bottom: 4vw;

    &::placeholder {
      color: var(--input-text-placeholder);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: all 5000s ease-in-out 0s;
    }
  }
`;

export const Input = styled(_Input)`
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
`;

export const InputDate = styled(DatePicker)`
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #fff;
  background-color: #251f2b;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
`;

export const Button = styled(_Button)`
  width: 100%;
  border-color: var(--button-primary-border);
  background-color: var(--button-primary) !important;
  color: var(--button-primary-text);
  height: 44px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 10px;

  &:hover, &:focus, &:active {
    border-color: var(--button-primary-border-hover);
    background-color: var(--button-primary-hover) !important;
    color: var(--button-primary-text-hover);
  }
`;

export const Link = styled(_Link)`
  color: var(--anchor-primary);

  &.primary-color {
    color: var(--button-primary-border);

    &:hover {
    color: var(--button-primary-border);
    }
  }

  &:hover {
    color: var(--primary-text);
  }

  svg {
    margin-right: 10px;
  }
`;

export const MenuButton = styled(_Button)`
  margin: 5px 0 0;
  font-weight: bold;
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 0;
  font-size: 16px;
  transition: background 0.2s;
`;
