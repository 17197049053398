import axios from 'axios';
import * as dotenv from 'dotenv';

dotenv.config();

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.defaults.headers['Content-Type'] = 'application/json; charset=utf-8';

export default api;
