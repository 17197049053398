import { combineReducers } from 'redux';

import auth from './auth/reducer';
import game from './game/reducer';
import gameTip from './gameTip/reducer';

export default combineReducers({
  auth,
  game,
  gameTip
});