import styled from 'styled-components';
import { Modal as _Modal } from 'antd';

export const Container = styled.div`
  
`;

export const Modal = styled(_Modal)`

`;
