import produce from 'immer';

const INITIAL_STATE = {
  profile: null,
  list: [],
  registry: {},
  loading: false,
  error: false
};

export default function gameTip(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@gameTips/CHECK_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
        draft.list = [];
      });

    case '@gameTips/CHECK_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.list = action.payload.data;
      });

    case '@gameTips/CHECK_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
        draft.list = [];
      });

    default:
      return state;
  }
}
