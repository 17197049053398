import styled from 'styled-components';
import { Col, Space } from 'antd';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  background-color: var(--header-background);
  z-index: 7777;
`;

export const Content = styled(Space)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  user-select: none;

  h1 {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
  }

  img {
    height: 70px;
  }
`;

export const UserInfo = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 8px;
`;

export const UserName = styled.span`
  color: rgba(255, 255, 255, 0.65);
  line-height: 22px;
`;

export const UserPerfil = styled.span`
  color: rgba(255, 255, 255, 0.65);
  font-size: 8px;
  line-height: 10px;
  text-transform: uppercase;
`;
