import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import AuthLayout from '~/pages/_layouts/auth';
import DefaultLayout from '~/pages/_layouts/default';

import { store } from '~/store';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  breadcrumb,
  path,
  ...rest
}) {
  var Layout = DefaultLayout;

  return (
    <Route
      {...rest}
      render={props => (
        <Layout breadcrumb={breadcrumb}>
          <Component  {...props} />
        </Layout>
      )}
    />
  )

}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
