import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment';

import api from '~/services/api';
import { isNullOrEmpty } from '~/helpers/util';

import { gameTipCheckSuccess, gameTipCheckFailure } from './actions';

export function* onCheck({ payload }) {
  try {
    let { id, piece, place } = payload;

    const response = yield call(api.get, `/games/${id}/piece-place-check?piece=${piece}&place=${place}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(gameTipCheckSuccess(data));
  }
  catch (err) {
    toast.error(String(err));
    yield put(gameTipCheckFailure());
  }
}

export default all([
  takeLatest('@gameTips/CHECK_REQUEST', onCheck)
]);
